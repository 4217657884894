const FORMATS = {
  shape: { value: 'shape', text: 'Shape (QGIS)' },
  'shape-arcgis': { value: 'shape-arcgis', text: 'Shape (ArcMap)' },
  gpkg: { value: 'gpkg', text: 'GeoPackage (QGIS)' },
  'geodatabase-arcpro': { value: 'geodatabase-arcpro', text: 'Geodatabase (ArcGIS Pro)' },
  'geodatabase-arcgis': { value: 'geodatabase-arcgis', text: 'Geodatabase (ArcMap)' },
  geojson: { value: 'geojson', text: 'GeoJSON (QGIS)' },
  mapinfo: { value: 'mapinfo', text: 'TAB (Mapinfo)' },
  mif: { value: 'mif', text: 'MIF/MID (Mapinfo)' },
  kml: { value: 'kml', text: 'KML (Google Earth)' },
  postgresql: { value: 'postgresql', text: 'SQL (PostgreSQL/PostGIS)' },
  'pbf-osm': { value: 'pbf-osm', text: 'PBF (OSM)' },
  'xml-osm': { value: 'xml-osm', text: 'XML (OSM)' },
  pdf: { value: 'pdf', text: 'PDF' },
  csv: { value: 'csv', text: 'CSV (Excel)' },
  geotiff: { value: 'geotiff', text: 'GeoTIFF' },
};

const DEFAULT_VECTOR_FORMAT = 'gpkg';

const COMMON_VECTOR_FORMATS = [
  'gpkg',
  'shape',
  'shape-arcgis',
  'geodatabase-arcgis',
  'geojson',
  'mapinfo',
  'mif',
  'kml',
  'postgresql',
  'csv',
];

const OSM_FORMAT_LIST_FULL = [
  ...COMMON_VECTOR_FORMATS.slice(0, 3),
  'geodatabase-arcpro',
  ...COMMON_VECTOR_FORMATS.slice(3),
  'pbf-osm',
  'xml-osm',
  // 'pdf',
];

const OSM_FORMAT_LIST_FOR_BIG_AREA = OSM_FORMAT_LIST_FULL.filter((format) => format !== 'mapinfo');

const getFormatName = (key) => FORMATS[key] && FORMATS[key].text;

const getFullFormatList = (keys) => keys.map((formatKey) => FORMATS[formatKey]);

export {
  FORMATS,
  COMMON_VECTOR_FORMATS,
  DEFAULT_VECTOR_FORMAT,
  OSM_FORMAT_LIST_FULL,
  OSM_FORMAT_LIST_FOR_BIG_AREA,
  getFormatName,
  getFullFormatList,
};
