const formatRegionCode = (regionCode) => (regionCode.toLowerCase() === 'custom' ? 'custom' : regionCode.toUpperCase());

const isCustomRegion = (regionCode) => regionCode.toLowerCase() === 'custom';

const isRegionFromCountry = (regionCode, countryCode) => {
  const countryCodeLowerCased = countryCode.toLowerCase();
  const regionCodeStartsWith = regionCode.toLowerCase().slice(0, 3);
  return regionCodeStartsWith === `${countryCodeLowerCased}` || regionCodeStartsWith === `${countryCodeLowerCased}-`;
};

const isRegionFromCountries = (regionCode, countryCodes) => {
  return countryCodes.some((countryCode) => isRegionFromCountry(regionCode, countryCode));
};

const sanitizeRegionSourceOptionsForOrder = (regionSource, regionSourceOptions) => {
  const { code, name } = regionSourceOptions || {};
  return regionSource === 'catalog' ? { code, name } : regionSourceOptions;
};

export {
  formatRegionCode,
  isCustomRegion,
  isRegionFromCountry,
  sanitizeRegionSourceOptionsForOrder,
  isRegionFromCountries,
};
