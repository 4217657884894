const promotions = {
  namespaced: true,
  state: () => ({
    orderCoupon: undefined,
    orderCouponStatus: 'iddle',
    orderCouponError: undefined,
    productCoupon: undefined,
    productCouponStatus: 'iddle',
    productCouponError: undefined,
  }),
  mutations: {
    SET_ORDER_COUPON(state, orderCoupon) {
      state.orderCoupon = orderCoupon;
    },
    SET_ORDER_COUPON_STATUS(state, orderCouponStatus) {
      state.orderCouponStatus = orderCouponStatus;
    },
    SET_ORDER_COUPON_ERROR(state, orderCouponError) {
      state.orderCouponError = orderCouponError;
    },
    SET_PRODUCT_COUPON(state, productCoupon) {
      state.productCoupon = productCoupon;
    },
    SET_PRODUCT_COUPON_STATUS(state, productCouponStatus) {
      state.productCouponStatus = productCouponStatus;
    },
    SET_PRODUCT_COUPON_ERROR(state, productCouponError) {
      state.productCouponError = productCouponError;
    },
  },
  actions: {
    resetOrderCoupon({ commit }) {
      commit('SET_ORDER_COUPON', undefined);
      commit('SET_ORDER_COUPON_STATUS', 'iddle');
      commit('SET_ORDER_COUPON_ERROR', undefined);
    },
    resetProductCoupon({ commit }) {
      commit('SET_PRODUCT_COUPON', undefined);
      commit('SET_PRODUCT_COUPON_STATUS', 'iddle');
      commit('SET_PRODUCT_COUPON_ERROR', undefined);
    },
  },
};

export default promotions;
