const storeState = {
  currentPage: null,
  config: null,
};

const mutations = {
  SET_CURRENT_PAGE(state, currentPage) {
    state.currentPage = currentPage;
  },
  SET_CONFIG(state, config) {
    state.config = config;
  },
};

const actions = {
  init({ commit }, config) {
    commit('SET_CURRENT_PAGE', window.currentPage);
    commit('SET_CONFIG', config);
  },
};

const baseApp = {
  namespaced: true,
  state: storeState,
  mutations,
  actions,
};

export default baseApp;
