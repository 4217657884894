import UserService from '../../services/UserService';

const storeState = {
  isAuthenticated: false,
  username: null,
  firstName: null,
  lastName: null,
  email: null,
  userpicUrl: null,
  plan: null,
  webgisUrl: null,
  company: null,
  phone: null,
  timezone: null,
  ipCountry: undefined, // undefined = hasn't been set yet, null - hasn't managed to get
};

const mutations = {
  SET_USERNAME(state, username) {
    state.username = username;
  },
  SET_FIRST_NAME(state, firstName) {
    state.firstName = firstName;
  },
  SET_LAST_NAME(state, lastName) {
    state.lastName = lastName;
  },
  SET_EMAIL(state, email) {
    state.email = email;
  },
  SET_USERPIC_URL(state, userpicUrl) {
    state.userpicUrl = userpicUrl;
  },
  SET_USER_IS_AUTHENTICATED(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
  SET_PLAN(state, plan) {
    state.plan = plan;
  },
  SET_WEBGIS_URL(state, webgisUrl) {
    state.webgisUrl = webgisUrl;
  },
  SET_COMPANY(state, company) {
    state.company = company;
  },
  SET_INDUSTRY(state, industry) {
    state.industry = industry;
  },
  SET_PHONE(state, phone) {
    state.phone = phone;
  },
  SET_TIMEZONE(state, timezone) {
    state.timezone = timezone;
  },
  SET_USER_IP_COUNTRY(state, country) {
    state.ipCountry = country;
  },
};

const actions = {
  fetchUserData({ commit }) {
    return UserService.getUserData()
      .then((data) => {
        commit('SET_USERNAME', data.username);
        commit('SET_FIRST_NAME', data.first_name);
        commit('SET_LAST_NAME', data.last_name);
        commit('SET_USERPIC_URL', data.userpicUrl);
        commit('SET_USER_IS_AUTHENTICATED', data.isAuthenticated);
        commit('SET_PLAN', data.plan);
        commit('SET_WEBGIS_URL', data.webgisUrl);
        commit('SET_COMPANY', data.company);
        commit('SET_INDUSTRY', data.industry);
        commit('SET_PHONE', data.phone);
        commit('SET_EMAIL', data.email);
        commit('SET_TIMEZONE', data.timezone);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(`There was a problem fetching user: ${error.message}`);
      });
  },
};

const baseUser = {
  namespaced: true,
  state: storeState,
  mutations,
  actions,
};

export default baseUser;
