const order = {
  namespaced: true,
  state: () => ({
    email: undefined,
    isLegalEntity: false,
    entityDataFileId: undefined,
    comment: undefined,
    totalPrice: undefined,
    totalPriceWithDiscount: undefined,
    discount: undefined,
  }),
  getters: {
    finalTotalPrice(state) {
      return (!state.isLegalEntity && state.totalPriceWithDiscount) || state.totalPrice;
    },
  },
  mutations: {
    SET_ORDER_EMAIL(state, email) {
      state.email = email;
    },
    SET_IS_LEGAL_ENTITY(state, isLegalEntity) {
      state.isLegalEntity = isLegalEntity;
    },
    SET_ENTITY_DATA_FILE_ID(state, fileId) {
      state.entityDataFileId = fileId;
    },
    SET_ORDER_COMMENT(state, comment) {
      state.comment = comment;
    },
    SET_TOTAL_PRICE(state, price) {
      state.totalPrice = price;
    },
    SET_TOTAL_PRICE_WITH_DISCOUNT(state, priceWithDiscount) {
      state.totalPriceWithDiscount = priceWithDiscount;
    },
    SET_DISCOUNT(state, discount) {
      state.discount = discount;
    },
  },
  actions: {
    resetPrice({ commit }) {
      commit('SET_TOTAL_PRICE', undefined);
      commit('SET_TOTAL_PRICE_WITH_DISCOUNT', undefined);
      commit('SET_DISCOUNT', undefined);
    },
  },
};

export default order;
