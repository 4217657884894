import store from '@/store';
import i18n from '@/i18n';
import dayjs from 'dayjs';

const COUPON_ERROR_MESSAGE_IDS = {
  err_not_found: 'order.promoCodeDoesNotExist',
  err_expired_date: 'order.promocodeHasExpired',
  err_expired_count: 'order.promocodeHasExpired',
  err_max_price: 'order.itemLimitExceeded',
  unknown_error: 'app.somethingWentWrong',
};

const setOrderCouponToStore = ({ coupon, couponStatus }) => {
  store.commit('promotions/SET_ORDER_COUPON', coupon);

  if (couponStatus === 'ok' || couponStatus === 'success') {
    store.commit('promotions/SET_ORDER_COUPON_STATUS', 'success');
    if (window.ym) window.ym(42050389, 'reachGoal', 'coupon-success');
  } else if (coupon && couponStatus && couponStatus !== 'loading' && couponStatus !== 'no_coupon') {
    const couponError =
      couponStatus && COUPON_ERROR_MESSAGE_IDS[couponStatus]
        ? i18n.t(`${COUPON_ERROR_MESSAGE_IDS[couponStatus]}`)
        : i18n.t(`${COUPON_ERROR_MESSAGE_IDS.unknown_error}`);
    store.commit('promotions/SET_ORDER_COUPON_STATUS', 'failed');
    store.commit('promotions/SET_ORDER_COUPON_ERROR', couponError);
    if (window.ym) window.ym(42050389, 'reachGoal', 'coupon-failure');
  } else if (!coupon && couponStatus === 'no_coupon') {
    store.commit('promotions/SET_ORDER_COUPON_STATUS', 'failed');
    store.commit('promotions/SET_ORDER_COUPON_ERROR', i18n.t('order.pleaseEnterYourPromocode'));
  }
};

const setProductCouponToStore = ({ coupon, couponStatus }) => {
  store.commit('promotions/SET_PRODUCT_COUPON', coupon);

  if (couponStatus === 'ok') {
    store.commit('promotions/SET_PRODUCT_COUPON_STATUS', 'success');
    if (window.ym) window.ym(42050389, 'reachGoal', 'coupon-success');
  } else if (
    coupon &&
    couponStatus &&
    couponStatus !== 'loading' &&
    couponStatus !== 'no_coupon' &&
    couponStatus !== 'iddle'
  ) {
    const couponError =
      couponStatus && COUPON_ERROR_MESSAGE_IDS[couponStatus]
        ? i18n.t(`${COUPON_ERROR_MESSAGE_IDS[couponStatus]}`)
        : i18n.t(`${COUPON_ERROR_MESSAGE_IDS.unknown_error}`);
    store.commit('promotions/SET_PRODUCT_COUPON_STATUS', 'failed');
    store.commit('promotions/SET_PRODUCT_COUPON_ERROR', couponError);
    if (window.ym) window.ym(42050389, 'reachGoal', 'coupon-failure');
  }
};

let removeCouponActivationFromRouteGuard;

const disableCouponActivationFromRoute = ({ router, currentRoute }) => {
  console.log('disable coupon');
  if (removeCouponActivationFromRouteGuard) removeCouponActivationFromRouteGuard();
  localStorage.removeItem('promo');
  // eslint-disable-next-line no-unused-vars
  const { promo, ...queryWithoutPromo } = currentRoute.query;
  router.replace({ ...currentRoute, query: queryWithoutPromo });
};

const enableCouponActivationFromRoute = ({ router }) => {
  if (removeCouponActivationFromRouteGuard) removeCouponActivationFromRouteGuard();

  let localStoragePromoObject;
  let localStoragePromo;

  try {
    localStoragePromoObject = localStorage.getItem('promo') && JSON.parse(localStorage.getItem('promo'));
  } catch (error) {
    console.warn('localStorage promo is not valid JSON');
    localStorage.removeItem('promo');
  }

  localStoragePromo = localStoragePromoObject?.promo;
  const timestamp = localStoragePromoObject?.timestamp;
  const isLocalStoragePromoExpired = timestamp && !dayjs().isSame(timestamp, 'day');

  if (isLocalStoragePromoExpired) {
    localStorage.removeItem('promo');
    localStoragePromo = undefined;
  }

  removeCouponActivationFromRouteGuard = router.beforeEach((to, _, next) => {
    if (to.query.promo && localStoragePromo !== to.query.promo)
      localStorage.setItem('promo', JSON.stringify({ promo: to.query.promo, timestamp: Date.now() }));

    if (!to.query.promo && localStoragePromo) return next({ ...to, query: { ...to.query, promo: localStoragePromo } });

    return next();
  });
};

export {
  setOrderCouponToStore,
  setProductCouponToStore,
  enableCouponActivationFromRoute,
  disableCouponActivationFromRoute,
  COUPON_ERROR_MESSAGE_IDS,
};
