import Vue from 'vue';

// import config from '@/config'; // remove after moving all pages to vue layouts
import { NoticeSetup } from '@nextgis/vuetify-notice';
import router from './router';
import store from './store';
import i18n from './i18n';
import vuetify from './plugins/vuetify';
import '@ngservices_front/ui-lib.js';
import '@mdi/font/css/materialdesignicons.css';
import * as promotionService from './services/promotionService';
// eslint-disable-next-line import/order
import App from '@/App.vue';
// Styles
import '@ngservices_front/scss/main.scss';
import './scss/main.scss';

Vue.config.productionTip = false;
Vue.prototype.$publicPath = process.env.VUE_APP_PUBLIC_PATH;

Vue.use(NoticeSetup, {
  vuetify,
  notice: {
    icon: 'mdi-alert-circle',
  },
});

promotionService.enableCouponActivationFromRoute({ router });

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
