import { sanitizeRegionSourceOptionsForOrder } from '@/utils/regionUtils';

const regions = {
  namespaced: true,
  state: () => ({
    currentRegion: undefined,
    currentRegionCode: undefined,
    currentRegionGeometry: undefined,
    regionSource: undefined,
    // TODO: regionSourceOptions duplicates currentRegion when regionSource=catalog. Refactor to avoid duplication
    // TODO: remove 'current' from field names
    regionSourceOptions: undefined,
  }),
  getters: {
    currentRegionName: (state) => state.currentRegion && state.currentRegion.name,
    regionForOrder: (state) => ({
      code: state.currentRegionCode,
      name: state.currentRegion?.name,
      country: state.currentRegion?.country,
      geometry: state.currentRegionGeometry?.wkt,
      regionSource: state.regionSource,
      regionSourceOptions: sanitizeRegionSourceOptionsForOrder(state.regionSource, state.regionSourceOptions),
    }),
  },
  mutations: {
    SET_CURRENT_REGION(state, region) {
      state.currentRegion = region; // {code, geometry}
    },
    SET_CURRENT_REGION_CODE(state, code) {
      state.currentRegionCode = code;
    },
    SET_CURRENT_REGION_GEOMETRY(state, geometry) {
      state.currentRegionGeometry = geometry;
    },
    SET_REGION_SOURCE(state, regionSource) {
      state.regionSource = regionSource;
    },
    SET_REGION_SOURCE_OPTIONS(state, regionSourceOptions) {
      state.regionSourceOptions = regionSourceOptions;
    },
  },
  actions: {},
};

export default regions;
